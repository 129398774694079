import React, { useEffect, useState } from "react";
import "./Stepper.css";

const Stepper = ({ step }) => {
  const [lineStates, setLineStates] = useState([false, false]);
  const [dotStates, setDotStates] = useState([true, false, false]);

  useEffect(() => {
    if (step > 1) {
      // Fill line first
      const lineIndex = step - 2;
      setLineStates((prev) =>
        prev.map((state, idx) => (idx === lineIndex ? true : state))
      );

      // Fill the next dot after line fills
      setTimeout(() => {
        setDotStates((prev) =>
          prev.map((state, idx) => (idx === step - 1 ? true : state))
        );
      }, 70); // Delay matches the line animation time
    }
  }, [step]);

  return (
    <div className="stepper">
      {Array(3)
        .fill("")
        .map((_, index) => (
          <React.Fragment key={index}>
            {/* Step (dot) */}
            <div className={`step ${dotStates[index] ? "active" : ""}`}>
              {index + 1}
            </div>

            {/* Line (connecting line) */}
            {index < 2 && (
              <div
                className={`line ${lineStates[index] ? "active" : ""}`}
              ></div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default Stepper;
