import React, { useEffect } from "react";

const TradingView = () => {
  useEffect(() => {
    // Ensure the script is available in the global scope
    const { TradingView } = window;

    if (TradingView) {
      new TradingView.widget({
        container: "chartContainer",
        locale: "en",
        library_path: "/charting_library/",
        datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
          "https://demo-feed-data.tradingview.com"
        ),
        symbol: "AAPL",
        interval: "1D",
        fullscreen: false,
        width: "100%",
        height: "100%",
        debug: true,
        theme: "Dark",
        custom_indicators_getter: function (PineJS) {
          return Promise.resolve([
            {
              name: "Custom Styles For Every Point",
              metainfo: {
                _metainfoVersion: 51,
                id: "CustomStylesForEveryPoint@tv-basicstudies-1",
                description: "Custom Styles For Every Point X-Algos",
                shortDescription: "Custom Styles For Every Point",
                is_price_study: false,
                isCustomIndicator: true,
                plots: [
                  {
                    id: "plot_0",
                    type: "line",
                  },
                  {
                    id: "plot_1",
                    type: "colorer",
                    target: "plot_0",
                    palette: "paletteId1",
                  },
                ],
                palettes: {
                  paletteId1: {
                    colors: {
                      0: {
                        name: "First color",
                      },
                      1: {
                        name: "Second color",
                      },
                    },
                  },
                },
                defaults: {
                  palettes: {
                    paletteId1: {
                      colors: {
                        0: {
                          color: "red",
                          width: 1,
                          style: 0,
                        },
                        1: {
                          color: "blue",
                          width: 3,
                          style: 1,
                        },
                      },
                    },
                  },
                  styles: {},
                  precision: 4,
                  inputs: {},
                },
                styles: {
                  plot_0: {
                    title: "Equity value",
                    histogramBase: 0,
                  },
                },
                inputs: [],
                format: {
                  type: "price",
                  precision: 4,
                },
              },
              constructor: function () {
                this.main = function (context, inputCallback) {
                  this._context = context;
                  this._input = inputCallback;

                  const value = Math.random() * 200;
                  const colorIndex = value > 100 ? 0 : 1;

                  return [value, colorIndex];
                };
              },
            },
          ]);
        },
      });
    }
  });

  return (
    <div
      id="chartContainer"
      className="container"
      style={{ height: "100vh", width: "100%" }}
    ></div>
  );
};

export default TradingView;
