import React, { useEffect, useState } from "react";
import {
  FaUser,
  FaExchangeAlt,
  FaChartLine,
  FaGift,
  FaInfoCircle,
  FaLifeRing,
  FaSignOutAlt,
  FaWallet,
  FaSun,
  FaMoon,
  FaLightbulb,
  FaStar,
  FaCheckCircle,
} from "react-icons/fa";
import Navbar from "./Navbar";
import "./profile.css";
import Switch from "react-switch";
import profile from "../images/profile.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Transaction from "./Transaction.js";
import AddFundsUI from "./AddFundsUI.js";
import axios from "axios";
import { ProductionUrl } from "../URL/url.js";
import { FaRegCopy } from "react-icons/fa6";
import defaultProfileImage from "../images/profile.png";
import WithdrawFundssUI from "./WithdrawFundsUI.js";
import { RiCoupon2Fill } from "react-icons/ri";
import Refferal from "./Referral.js";
import Coupons from "./Coupons.js";
import { Modal } from "react-bootstrap";
import ReactModal from "react-modal";
import zIndex from "@mui/material/styles/zIndex.js";

function Profile({ darkMode, toggleDarkMode }) {
  const [activeSection, setActiveSection] = useState("Profile"); // State for active section
  const [loading, setLoading] = useState(true);
  const Email = useSelector((state) => state.email.email);
  const navigate = useNavigate();
  const [profileURL, setProfileURL] = useState("");
  const userSchema = useSelector((state) => state.account.userSchemaRedux);
  const [firstName, lastName] = userSchema.Name.split(" ");
  const [activeTab, setActiveTab] = useState("AddFunds");
  const [isOpen, setIsOpen] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [loadingSessions, setLoadingSessions] = useState(true);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const url =
    process.env.NODE_ENV === "production"
      ? ProductionUrl
      : "http://localhost:5000";

  const handleCopy = () => {
    if (userSchema?.XalgoID) {
      // Copy the Client ID to the clipboard
      navigator.clipboard.writeText(userSchema.XalgoID);
    } else {
      alert("No Client ID to copy!");
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const clientId = userSchema.XalgoID;
        const sessions = await axios.post(`${url}/getSessions`, { clientId });
        console.log(sessions);
        setSessions(sessions.data);
        console.log("Fetching user schema:", userSchema);
        const response = await axios.post(`${url}/profile`, { Email });

        console.log("Email sent:", Email);
        console.log("Response data:", response.data);
        if (response.data.profile_img) {
          setProfileURL(response.data.profile_img);
        } else {
          setProfileURL(defaultProfileImage);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoadingSessions(false);
      }
    };

    fetchProfileData();
  }, [Email, url, userSchema]);

  const transactions = [
    {
      date: "21 March 2021",
      time: "09:16",
      transactionId: "OP01214784",
      amount: "$250 USD",
      status: "Receive",
    },
    {
      date: "20 March 2021",
      transactionId: "OP01214784",
      time: "09:16",
      amount: "-$20 USD",
      status: "Transfer",
    },
    {
      date: "19 March 2021",
      transactionId: "OP87452148",
      time: "09:16",
      amount: "-$80 USD",
      status: "Payment",
    },
    {
      date: "18 March 2021",
      transactionId: "OP32201425",
      time: "09:16",
      amount: "$100 USD",
      status: "Receive",
    },
    {
      date: "19 March 2021",
      transactionId: "OP34012458",
      time: "09:16",
      amount: "$210 USD",
      status: "Withdraw",
    },
    {
      date: "12 March 2021",
      transactionId: "OP82145784",
      time: "09:16",
      amount: "-$80 USD",
      status: "Payment",
    },
    {
      date: "10 March 2021",
      transactionId: "OP21547895",
      time: "09:16",
      amount: "$160 USD",
      status: "Deposit",
    },
    {
      date: "05 March 2021",
      transactionId: "OP21547854",
      time: "09:16",
      amount: "$100 USD",
      status: "Receive",
    },
    {
      date: "05 March 2021",
      transactionId: "OP21547854",
      time: "09:16",
      amount: "$100 USD",
      status: "Receive",
    },
  ];

  const handleSectionClick = (section) => {
    setActiveSection(section); // Change active section based on user click
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const logout = async () => {
    try {
      const response = await axios.post(`${url}/logout`);
      console.log(response.data);

      if (response.data.logout) {
        // Clear session data on the client side
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("user"); // Clear any session data if stored
        navigate("/"); // Redirect to login or home page
      } else {
        localStorage.removeItem("isLoggedIn");
        sessionStorage.removeItem("user"); // Clear any session data if stored
        navigate("/");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "2",
    },
    content: {
      width: "300px",
      borderRadius: "16px",
      padding: "20px",
      background: darkMode ? "black" : "white",
      textAlign: "center",
      position: "relative",
      inset: "unset", // Prevents default centering issues
    },
  };

  const styles = {
    iconContainer: {
      backgroundColor: "#FEECEC",
      width: "50px",
      height: "50px",
      borderRadius: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto 16px",
    },
    icon: {
      fontSize: "24px",
      color: "#E63946",
    },
    title: {
      fontSize: "18px",
      fontWeight: "600",
      margin: "16px 0 8px",
      color: darkMode ? "white" : "black",
    },
    message: {
      fontSize: "14px",
      color: darkMode ? "white" : "#555",
      marginBottom: "24px",
    },
    confirmButton: {
      backgroundColor: "#E63946",
      color: "#fff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "8px",
      fontSize: "14px",
      fontWeight: "600",
      cursor: "pointer",
      width: "100%",
      marginBottom: "12px",
    },
    cancelButton: {
      backgroundColor: "#F0F0F0",
      color: "#333",
      border: "none",
      padding: "10px 20px",
      borderRadius: "8px",
      fontSize: "14px",
      fontWeight: "600",
      cursor: "pointer",
      width: "100%",
    },
  };

  const buttonStyle = (tabName) => ({
    flex: 1,
    width: "10%",
    padding: "10px 20px",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border:
      tabName === activeTab
        ? "2px solid #FFA500"
        : "2px solid var(--text-color)",
    borderRadius: "8px",
    margin: "0 5px",
    // color: tabName === activeTab ? "#fff" : "var(--text-color)",
    backgroundColor: tabName === activeTab ? "#FFA500" : "transparent",
    fontWeight: tabName === activeTab ? "bold" : "normal",
  });

  return (
    <div>
      <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <div className="profile">
        <div className="profile-sidebar">
          <ul>
            <li
              className={`sidebar-item ${
                activeSection === "Profile" ? "active" : ""
              }`}
              onClick={() => handleSectionClick("Profile")}
            >
              <FaUser className="sidebar-icon" />
              <span className="sidebar-text">Profile</span>
            </li>
            <li className="sidebar-item">
              <div
                onClick={toggleDarkMode} // Toggle theme when clicked on icon or text
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <FaMoon className="sidebar-icon" />
                <span className="sidebar-text">Dark Mode</span>
              </div>

              <div
                className="theme-toggle-switch"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Switch
                  checked={darkMode} // Bind to current theme state
                  onChange={toggleDarkMode} // Trigger theme change on toggle
                  checkedIcon={false} // No icon inside the switch
                  uncheckedIcon={false}
                  onColor="#4F4F4F"
                  offColor="#C4C4C4"
                  height={22} // Adjust the height of the switch
                  width={40} // Adjust the width of the switch
                  handleDiameter={15} // Adjust the size of the toggle button (circle)
                />
              </div>
            </li>

            <li
              className={`sidebar-item ${
                activeSection === "Transaction" ? "active" : ""
              }`}
              onClick={() => handleSectionClick("Transaction")}
            >
              <FaExchangeAlt className="sidebar-icon" />
              <span className="sidebar-text">Transaction</span>
            </li>
            <li className="sidebar-item">
              <FaChartLine className="sidebar-icon" />
              <span className="sidebar-text">Analytics</span>
            </li>
            <li
              className={`sidebar-item ${
                activeSection === "Wallet" ? "active" : ""
              }`}
              onClick={() => handleSectionClick("Wallet")}
            >
              <FaWallet className="sidebar-icon" />
              <span className="sidebar-text">Wallet</span>
            </li>

            <li
              className={`sidebar-item ${
                activeSection === "Referral" ? "active" : ""
              }`}
              onClick={() => handleSectionClick("Referral")}
            >
              <FaGift className="sidebar-icon" />
              <span className="sidebar-text">Referral </span>
            </li>

            <li
              className={`sidebar-item ${
                activeSection === "Coupons" ? "active" : ""
              }`}
              onClick={() => handleSectionClick("Coupons")}
            >
              <RiCoupon2Fill className="sidebar-icon" />
              <span className="sidebar-text">Coupons </span>
            </li>

            <li className="sidebar-item">
              <FaInfoCircle className="sidebar-icon" />
              <span className="sidebar-text">Terms & Conditions</span>
            </li>
            <li className="sidebar-item">
              <FaLifeRing className="sidebar-icon" />
              <span className="sidebar-text">Help & Support</span>
            </li>
            <li className="sidebar-item" onClick={openModal}>
              <FaSignOutAlt className="sidebar-icon" />
              <span className="sidebar-text">Logout</span>
            </li>
          </ul>
        </div>

        {/* Conditional Rendering for Profile and Transaction */}
        <div className="profile-second">
          {activeSection === "Profile" && (
            <div className="profile-right-ajsndajskd">
              <div className="profile-right-first">
                {/* User image with Skeleton loader */}
                <div className="user-image">
                  {loading ? (
                    <Skeleton variant="circular" width={50} height={50} />
                  ) : (
                    <img
                      src={profileURL || defaultProfileImage}
                      height="50px"
                      alt="User"
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </div>

                <div className="user-info">
                  <div className="user-info-first">
                    {/* Username and Email with Skeleton loaders */}
                    <div
                      className="client-id-profile"
                      style={{ fontSize: "1em" }}
                    >
                      {loading ? (
                        <Skeleton width="80%" />
                      ) : (
                        <>
                          Client ID:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {userSchema?.XalgoID}{" "}
                          </span>
                          <FaRegCopy
                            color="#FCD535"
                            onClick={handleCopy}
                            style={{ cursor: "pointer" }}
                          />
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              backgroundColor: "#0f5132", // Green background
                              color: "#fff",
                              borderRadius: "4px",
                              padding: "1.5px 6px",
                              marginLeft: "1em",
                              fontSize: "0.6em",
                            }}
                          >
                            <FaCheckCircle style={{ marginRight: "6px" }} />{" "}
                            Verified
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      className="wxobt"
                      style={{ fontSize: "0.8em", overflowWrap: "anywhere" }}
                    >
                      {loading ? <Skeleton width="80%" /> : <>{Email}</>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-right-first-asdhasdhas">
                <div className="user-info-second">
                  {/* Account Type and Member Since with Skeleton loaders */}

                  <div className="user-info">
                    <div className="ajshdhjabdasbdjsdaawdaw">
                      {loading ? (
                        <Skeleton width="80%" height="100%" />
                      ) : (
                        <>
                          <p className="ajsdgagdawtgdtyawgtyd ">First Name </p>
                          <span style={{ fontWeight: "bold" }}>
                            {firstName}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="ajshdhjabdasbdjsdaawdaw">
                      {loading ? (
                        <Skeleton width="80%" height="100%" />
                      ) : (
                        <>
                          <p className="ajsdgagdawtgdtyawgtyd">Last Name </p>
                          <span style={{ fontWeight: "bold" }}>{lastName}</span>
                        </>
                      )}
                    </div>
                    <div className="ajshdhjabdasbdjsdaawdaw">
                      {loading ? (
                        <Skeleton width="80%" height="100%" />
                      ) : (
                        <>
                          <p className="ajsdgagdawtgdtyawgtyd">
                            Mobile Number{" "}
                          </p>
                          <span style={{ fontWeight: "bold" }}>
                            {userSchema.MobileNo}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="user-sessions">
                <h3>Sessions</h3>
                {loadingSessions ? (
                  <Skeleton width="80%" height="20px" />
                ) : (
                  <ul>
                    {sessions.map((session, index) => (
                      <li key={index} style={{ marginBottom: "10px" }}>
                        <div>Client ID: {session.clientId}</div>
                        <div>Mobile Number: {session.mobileNumber}</div>
                        <div>
                          Expires: {new Date(session.expires).toLocaleString()}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}

          {activeSection === "Transaction" && (
            <div className="transaction-info">
              <Transaction transactionData={transactions} loading={loading} />
            </div>
          )}

          {activeSection == "Wallet" && (
            <div className="wallet-container">
              <div className="wallet-buttons">
                <div
                  className="wallet-button"
                  style={buttonStyle("AddFunds")}
                  onClick={() => setActiveTab("AddFunds")}
                >
                  <span>
                    <i className="fas fa-plus-square"></i>
                  </span>
                  ADD FUNDS
                </div>
                <div
                  className="wallet-button wallet-withdraw"
                  style={buttonStyle("Withdraw")}
                  onClick={() => setActiveTab("Withdraw")}
                >
                  <span>
                    <i className="fas fa-arrow-up"></i>
                  </span>
                  WITHDRAW
                </div>
              </div>

              <div className="wallet-content">
                {activeTab === "AddFunds" && (
                  <div className="wallet-add-funds-ui">
                    <AddFundsUI />
                  </div>
                )}
                {activeTab === "Withdraw" && (
                  <div className="wallet-withdraw-ui">
                    <WithdrawFundssUI />
                  </div>
                )}
              </div>
            </div>
          )}

          {activeSection === "Referral" && (
            <div>
              <Refferal loading={loading} />
            </div>
          )}

          {activeSection === "Coupons" && (
            <>
              <Coupons loading={loading} />
            </>
          )}
        </div>
      </div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Logout Confirmation"
        style={customStyles}
      >
        <div style={styles.iconContainer}>
          <span role="img" aria-label="logout" style={styles.icon}>
            🔓
          </span>
        </div>
        <h2 style={styles.title}>Logout</h2>
        <p style={styles.message}>Are you sure you want to logout?</p>
        <button onClick={logout} style={styles.confirmButton}>
          Yes, Logout
        </button>
        <button onClick={closeModal} style={styles.cancelButton}>
          Cancel
        </button>
      </ReactModal>
    </div>
  );
}

export default Profile;
