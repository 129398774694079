import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  FaWhatsapp,
  FaInstagram,
  FaTwitter,
  FaCopy,
  FaGift,
  FaUserFriends,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import { IoIosArrowDropright } from "react-icons/io";
import { ProductionUrl } from "../URL/url";
import Swal from "sweetalert2";
import "./Referral.css";
import image from "../images/StrategyImage.jpeg"; // Import the default image
import { FaEdit, FaSave } from "react-icons/fa";
import image2 from "../images/image.png";
import { IoIosArrowDropup } from "react-icons/io";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const Refferal = () => {
  const userSchema = useSelector((state) => state.account.userSchemaRedux);
  const Email = userSchema.Email;
  const [xalgosId, setXalgoId] = useState();
  const [referReward, setReferReward] = useState();
  const [unpaidXalgoIDs, setUnpaidXalgoIDs] = useState([]);
  const [promotingRewardAMT, setPromotingRewardAMT] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);
  const [isListVisible2, setIsListVisible2] = useState(false);
  const [paidXalgoIDs, setPaidXalgoIDs] = useState([]);

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };
  const toggleListVisibility2 = () => {
    setIsListVisible2(!isListVisible2);
  };
  const url =
    process.env.NODE_ENV === "production"
      ? ProductionUrl
      : "http://localhost:5000";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${url}/dbschema`, { Email });
        setXalgoId(response.data.Referr[0].PromoCode);
        setPromotingRewardAMT(response.data.Referr[0].PromotingRewardAMT);
        setReferReward(response.data.Referr[0]?.PromotingRewardAMT);
        const response2 = await axios.post(`${url}/pendingRefferals`, {
          Email,
        });
        setUnpaidXalgoIDs(response2.data.unpaidXalgoIDs ?? []);
        setPaidXalgoIDs(response2.data.paidXalgoIDs ?? []);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  // const handleSave = async () => {
  //   try {
  //     const response = await axios.post(${url}/updatePromoCode, {
  //       Email,
  //       newPromoCode: editableCode,
  //     });
  //     console.log("Promo code updated:", response.data);
  //     setXalgoId(editableCode);
  //     if (response.data.isTaken) {
  //       Swal.fire({
  //         title: "Error",
  //         text: "Promo Code is taken",
  //         icon: "error",
  //       });
  //     }
  //     if (response.data.isUpdate) {
  //       Swal.fire({
  //         title: "Done",
  //         icon: "success",
  //       });
  //     }
  //   } catch (e) {
  //     console.log("Error updating promo code:", e);
  //   } finally {
  //     setIsEditing(false);
  //   }
  // };

  const handleShare = () => {
    const promoMessage = `Use my promo code ${xalgosId} and join now! Click here: http://xalgos.in/n`;
    window.open(
      `https://wa.me/?text=${encodeURIComponent(promoMessage)}`,
      "_blank"
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(xalgosId).then(
      () => {
        alert("Copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  return (
    <div className="container-abcefgh">
      <div className="card-wrapper-xyz">
        <div>
          <img src={image2} className="image-reffer-aksjdb" />
        </div>
        <div>
          <p className="withdrawable-text-kahbsdhsjd">
            Use the referral code to invite your friends and earn a withdrawable
            amount ₹{promotingRewardAMT}
          </p>
          <div className="shareclass-12345">
            <div className="refferId-123456">
              <div
                className="copybtn12"
                onClick={handleCopy}
                style={{ cursor: "pointer" }}
              >
                <FaCopy />
              </div>
              <span className="poiuytgbnkl ml-2">{xalgosId}</span>
            </div>
            <div className="shareajsvd-123">
              <button
                className="askbdashdahsbdabsdhasbdhasd"
                onClick={handleShare}
                style={{ borderRadius: "9px", borderLeft: "1px solid grey" }}
              >
                <FaWhatsapp />
                <span className="asdfvbnm">Share on WhatsApp</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-wrapper-xyz2">
        <div className="mainrefferbox">
          <div className="referboxclass">
            <FaGift color="#FFD26C" className="iconajgsvdhgasd" />
          </div>
          <p className="ahsbfghsdvbfgshdvbgfhd">Refferal Reward</p>
          <p className="asdfghjiujnbvdfghjk">₹{referReward}</p>
        </div>
        <div className="mainrefferbox">
          <div className="referboxclass2">
            <FaUserFriends color="#E0F2EE" className="iconajgsvdhgasd" />
          </div>
          <p className="ahsbfghsdvbfgshdvbgfhd">Pending Refferals</p>
          <div className="d-flex justify-content-between">
            <p className="asdwedfghjmk">
              {unpaidXalgoIDs.length} invites pending
            </p>
            {isListVisible ? (
              <TiArrowSortedUp
                color="blue"
                fontSize={25}
                className="ahsdvbghasvdhgas"
                onClick={toggleListVisibility}
              />
            ) : (
              <TiArrowSortedDown
                color="blue"
                fontSize={25}
                className="ahsdvbghasvdhgas"
                onClick={toggleListVisibility}
              />
            )}
          </div>
          {isListVisible && (
            <div>
              {unpaidXalgoIDs.length > 0 ? (
                <ul>
                  {unpaidXalgoIDs.map((xalgoID, index) => (
                    <li key={index}>{xalgoID}</li>
                  ))}
                </ul>
              ) : (
                <p>No complete referrals found.</p>
              )}
            </div>
          )}
          {/* Completed Referrals Section */}
          <div className="d-flex justify-content-between">
            <p className="asdwedfghjmk">{paidXalgoIDs.length} completed</p>
            {isListVisible2 ? (
              <TiArrowSortedUp
                color="blue"
                fontSize={25}
                className="ahsdvbghasvdhgas"
                onClick={toggleListVisibility2}
              />
            ) : (
              <TiArrowSortedDown
                color="blue"
                fontSize={25}
                className="ahsdvbghasvdhgas"
                onClick={toggleListVisibility2}
              />
            )}
          </div>
          {isListVisible2 && (
            <div>
              {paidXalgoIDs.length > 0 ? (
                <ul>
                  {paidXalgoIDs.map((xalgoID, index) => (
                    <li key={index}>{xalgoID}</li>
                  ))}
                </ul>
              ) : (
                <p>No complete referrals found.</p>
              )}
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  );
};

export default Refferal;
