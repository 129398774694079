import React, { useEffect, useState } from "react";
import "./New_Signin.css";
import animationData from "../lotties/Signin.json";
import Lottie from "react-lottie";
import "animate.css";
import Loader from "./loader";
import "./loader.css";

import { useDispatch, useSelector } from "react-redux";
import "./menubar.css";
import { Link, useNavigate } from "react-router-dom";
import { setEmail } from "../actions/email_action";
import axios from "axios";
import "./loader.css";
import "./signin.css";
import { allClientData, auth, userSchemaRedux } from "../actions/actions";
import "sweetalert2/src/sweetalert2.scss";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { ProductionUrl } from "../URL/url";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleSignInButton from "./GoogleSignInButton.js";
import Stepper from "./Stepper.js";

function New_Signin() {
  const [aniLoading, setAniLoading] = useState(true); // State to manage animation loading
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState("");
  const [pass, passInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [pin, setPin] = useState("");
  const [realOtp, setRealOtp] = useState("");
  const [isSessionActive, setIsSessionActive] = useState(false); // New state to track session

  const isAuth = useSelector((state) => state.account.auth);
  console.log(isAuth);

  const [otp, setOtp] = useState(Array(6).fill(""));

  const url =
    process.env.NODE_ENV === "production"
      ? ProductionUrl
      : "http://localhost:5000";

  // Check session on component mount

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get(`${url}/check-session`, {
          withCredentials: true,
        });
        console.log(response.data);
        if (response.data.activeSession) {
          setIsSessionActive(true);
          console.log(isSessionActive);
          setUserInput(response.data.user.clientId);
        }
      } catch (error) {
        console.error("Error checking session:", error);
      }
    };

    checkSession();
  }, [navigate, url]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      // If the value is numeric, update the state and move to the next input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else {
      // Clear invalid input
      e.target.value = "";
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      if (index > 0 && otp[index] === "") {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleProceed = async (resend) => {
    console.log(resend);
    if (resend) {
      setLoading(false);
    }
    if (resend == null) {
      setLoading(true);
    }
    try {
      const response = await axios.post(`${url}/signin-step-1`, {
        userInput,
      });
      console.log(response);
      if (response.data.canSendOtp) {
        if (resend) {
          console.log("fhgjhkl");
        } else {
          setAnimation("slide-out-left");
          setTimeout(() => {
            setStep(2);
            setAnimation("slide-in-right");
          }, 300);
        }
        // setStep(2);
        const otpResponse = await axios.post(`${url}/signin-step-2`);
        if (otpResponse.data.otp) {
          console.log("==================>", otpResponse.data.otp);
          setRealOtp(otpResponse.data.otp);
        }
        // alert(otpResponse.data.otp);
      } else {
        toast.error(response.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Proceed error: ", error);
      toast.error("An error occurred during proceed!", {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleotp = () => {
    const otpString = otp.join("");
    // compare realOtp and otpString when service is avaliable
    if (realOtp === realOtp) {
      handleNextStep();
      toast.success("Proceed successful!", {
        position: "top-center",
        autoClose: 2000,
      });
      setStep(3);
    } else {
      toast.error("OTP is wrong", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userAgent = navigator.userAgent;
      console.log(userInput);
      const a = await axios.post(
        `${url}/verify-pin `,
        {
          userInput,
          deviceInfo: userAgent,
          pin,
        }
        // { withCredentials: true }
      );
      console.log(a);

      if (a.data.pin) {
        const Email = a.data.userSchema.Email;
        const userAgent = navigator.userAgent;
        const sendMail = await axios.post(`${url}/sendLoginMail`, {
          Email,
          deviceInfo: userAgent,
        });
        const profileData = await axios.post(`${url}/userinfo`, { Email });
        dispatch(allClientData(profileData.data));

        localStorage.setItem("isLoggedIn", true);
        dispatch(setEmail(a.data.userSchema.Email));
        dispatch(auth(true));

        const modalBackdrop = document.querySelector(".modal-backdrop");
        if (modalBackdrop) {
          modalBackdrop.classList.remove("modal-backdrop");
        }

        dispatch(userSchemaRedux(a.data.userSchema));
        navigate("/home", { state: { userEmail: Email } });
      } else {
        toast.error("Wrong PIN!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (e) {
      console.log("Error: " + e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // setLoading(true);
      setAniLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [timer, setTimer] = useState(0); // Initialize the timer state

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval); // Cleanup the interval on component unmount or when timer changes
    }
  }, [timer]);

  const resendOTP = () => {
    if (timer === 0) {
      // Trigger your resend OTP logic here
      console.log("OTP Resent");
      handleProceed(true);
      setTimer(30); // Start the 30-second timer
    }
  };
  const [animation, setAnimation] = useState("");
  const handleNextStep = () => {
    setAnimation("slide-out-left");
    setTimeout(() => {
      // setStep(step + 1);
      setAnimation("slide-in-right");
    }, 300); // Match animation duration
  };

  const handlePreviousStep = () => {
    setAnimation("slide-out-right");
    setTimeout(() => {
      setStep(step - 1);
      setAnimation("slide-in-left");
    }, 300); // Match animation duration
  };

  return (
    <div className="signin-container">
      <ToastContainer />
      <div
        className={`signin-lottie-animation ${
          aniLoading ? "animate_animated animate_fadeIn" : ""
        }`}
      >
        <Lottie options={defaultOptions} height={450} width={450} />
      </div>

      <div
        // className={`signin-login-box ${
        //   aniLoading ? "animate_animated animate_fadeIn" : ""
        // }`}
        className={`signin-login-box ${animation}`}
      >
        <Stepper step={step} />
        <h2>Welcome to X-Algos! 👋</h2>
        <p className="ranpq">
          Please sign in to your account and start the adventure
        </p>

        {isSessionActive ? (
          <>
            <form onSubmit={handleSubmit}>
              <label>Pin</label>
              <input
                className="signup-input"
                type="text"
                placeholder="Confirm Pin"
                value={pin}
                maxLength={4}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setPin(value);
                }}
                required
              />
              <button className="signin-login-button " type="submit">
                {loading ? (
                  <span className="loading-spinner"></span>
                ) : (
                  <span>"Submit"</span>
                )}
              </button>
            </form>
          </>
        ) : (
          <div className={`signin-content ${animation}`}>
            <form onSubmit={handleSubmit}>
              {step === 1 && (
                <>
                  <label>Client ID or Mobile Number</label>
                  <input
                    className="signin-input"
                    type="text"
                    maxLength={10}
                    placeholder="Enter Client Id or Mobile No"
                    value={userInput}
                    onChange={(e) => {
                      setUserInput(e.target.value);
                    }}
                    required
                  />
                  <button
                    type="button"
                    className="signin-login-button p-1"
                    onClick={(e) => handleProceed(false)}
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="loading-spinner"></span> // Add your spinner here
                    ) : (
                      <span className="asdfghjklqwertyuiop">Proceed</span>
                    )}
                  </button>
                </>
              )}

              {step === 2 && (
                <>
                  <label>Enter OTP</label>
                  <div className="otp-container d-flex justify-content-between">
                    {Array(6)
                      .fill("")
                      .map((_, index) => (
                        <input
                          id={`otp-input-${index}`}
                          key={index}
                          className={`otp-input ${
                            index === 3 ? "otp-gap" : ""
                          }`}
                          type="text"
                          maxLength="1"
                          value={realOtp[index]}
                          // value={otp[index]}
                          onChange={(e) => handleOtpChange(e, index)}
                          onKeyDown={(e) => handleKeyPress(e, index)}
                          inputMode="numeric"
                        />
                      ))}
                  </div>
                  <button
                    className="signin-login-button p-1"
                    type="button"
                    onClick={handleotp}
                  >
                    {loading ? (
                      <span className="loading-spinner"></span> // Add your spinner here
                    ) : (
                      <span className="asdfghjklqwertyuiop">Next</span>
                    )}
                  </button>
                </>
              )}

              {step === 3 && (
                <>
                  <label>Pin</label>
                  <input
                    className="signup-input"
                    type="text"
                    placeholder="Enter Pin"
                    value={pin}
                    maxLength={4}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      setPin(value);
                    }}
                    required
                  />
                  <button className="signin-login-button p-1" type="submit">
                    {loading ? (
                      <span className="loading-spinner"></span> // Add your spinner here
                    ) : (
                      <span className="asdfghjklqwertyuiop">Submit</span>
                    )}
                  </button>
                </>
              )}
            </form>
          </div>
        )}

        {step == 1 ? (
          <>
            <div className="signin-signup">
              New on our platform?{" "}
              <Link to="/signup" className="ajsndjkasndkjasndjkasnkjd">
                Create an account
              </Link>
            </div>
          </>
        ) : (
          ""
        )}

        {step == 2 ? (
          <div className="signin-options">
            <Link
              onClick={resendOTP}
              disabled={timer > 0}
              style={{ pointerEvents: timer > 0 ? "none" : "auto" }}
            >
              Resend OTP {timer > 0 && `(${timer}s)`}
            </Link>
          </div>
        ) : (
          ""
        )}

        {step == 3 ? (
          <div className="signin-options">
            <Link>Forgot PIN?</Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default New_Signin;
