import React, { useEffect, useState } from "react";
import { ProductionUrl } from "../URL/url";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./Coupons.css";

const Card = () => {
  const Email = useSelector((state) => state.email.email);
  const [userCoupon, setUserCoupon] = useState([]);
  const [timers, setTimers] = useState({});
  const url =
    process.env.NODE_ENV === "production"
      ? ProductionUrl
      : "http://localhost:5000";

  // Fetch coupons from the server
  useEffect(() => {
    const fetchCoupons = async () => {
      if (!Email) return;

      try {
        const response = await axios.post(`${url}/dbschema`, { Email });
        setUserCoupon(response.data.Referr[0].Coupons);
        console.log("Fetched Coupons:", response.data.Referr[0].Coupons);
      } catch (e) {
        console.error(e);
      }
    };

    fetchCoupons();
  }, [Email, url]);

  // Update timers for all coupons whenever userCoupon changes
  useEffect(() => {
    if (userCoupon.length === 0) return; // Avoid running the timer effect until coupons are fetched

    const interval = setInterval(() => {
      const newTimers = {};

      userCoupon.forEach((coupon) => {
        const expirationDate = new Date(coupon.ExpirationDate);

        const now = new Date();
        const timeLeft = expirationDate - now;

        if (timeLeft > 0) {
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

          newTimers[coupon.ExpirationDate] = { days, hours, minutes, seconds };
        } else {
          newTimers[coupon.ExpirationDate] = null;
        }
      });

      setTimers({ ...newTimers }); // Ensure state is updated correctly
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [userCoupon]); // Runs whenever userCoupon is updated

  if (userCoupon.length === 0) return <p>No Coupons</p>;

  return (
    <div>
      <div className="akhsgdyagdsagdjds">
        {userCoupon.map((coupon, index) => {
          const timer = timers[coupon.ExpirationDate];
          const isExpired = timer === null; // Check if coupon is expired

          return (
            <div
              key={index}
              className={`black-friday-component ${isExpired ? "expired" : ""}`}
            >
              <div className="coupon">
                <div className="box">
                  <span className="asbdhasbdjgasghvfahdsfah">
                    {coupon.RewardValue}%
                  </span>
                  <span>OFF</span>
                </div>
                <div className="diver" />
                <div className="content">
                  <span className="hsajavsbdvazmhbhjzdgsvdghsavjda">
                    {coupon.RewardName} <span>🎉</span>
                  </span>
                  <br></br>
                  <p
                    className={`askjgdjajdvaghsvdgas ${
                      isExpired ? "expired" : ""
                    } pt-3`}
                  >
                    {coupon.Description}
                    add link to open model
                  </p>
                </div>
              </div>
              <div>
                {timer ? (
                  <div className="timer">
                    <div className="item">
                      <div className="days">
                        <div className="day">{timer.days}</div>
                      </div>
                      <p>day</p>
                    </div>
                    <span className="colon">:</span>
                    <div className="item">
                      <div className="hours">
                        <div className="hour">{timer?.hours}</div>
                      </div>
                      <p className="asdfghjkwertyuio">hour</p>
                    </div>
                    <span className="colon">:</span>
                    <div className="item">
                      <div className="minutes">
                        <div className="min">{timer?.minutes}</div>
                      </div>
                      <p className="asdfghjkwertyuio">min</p>
                    </div>
                    <span className="colon">:</span>
                    <div className="item">
                      <div className="seconds">
                        <div className="sec">{timer?.seconds}</div>
                      </div>
                      <p className="asdfghjkwertyuio">sec</p>
                    </div>
                  </div>
                ) : (
                  <div className="timer">
                    <div className="item">
                      <div className="days">
                        <div className="day">-</div>
                      </div>
                      <p className="asdfghjkwertyuio">day</p>
                    </div>
                    <span className="colon">:</span>
                    <div className="item">
                      <div className="hours">
                        <div className="hour">-</div>
                      </div>
                      <p className="asdfghjkwertyuio">hour</p>
                    </div>
                    <span className="colon">:</span>
                    <div className="item">
                      <div className="minutes">
                        <div className="min">-</div>
                      </div>
                      <p>min</p>
                    </div>
                    <span className="colon">:</span>
                    <div className="item">
                      <div className="seconds">
                        <div className="sec">-</div>
                      </div>
                      <p>sec</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Card;
