import React, { useState } from "react";
import "./New_Signup.css";
import animationData from "../lotties/Signin.json";
import Lottie from "react-lottie";
import "animate.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Loader from "./loader";
import { toast, ToastContainer } from "react-toastify";
import { ProductionUrl } from "../URL/url";
import Stepper from "./Stepper";

function New_Signup() {
  const [step, setStep] = useState(1); // Current step in the process
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [gmailOtp, setGmailOtp] = useState(["", "", "", "", "", ""]);
  const [mobileOtp, setMobileOtp] = useState(["", "", "", "", "", ""]);
  const [getGmailOtp, setGetGmailOtp] = useState();
  const [GetMobileOtp, setGetMobileOtp] = useState();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [animationClass, setAnimationClass] = useState("");

  const url =
    process.env.NODE_ENV === "production"
      ? ProductionUrl
      : "http://localhost:5000";

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = async () => {
    if (email == "" || firstName == "" || lastName == "" || phone == "") {
      toast.error("Enter details", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      if (step === 1) {
        setLoading(true);
        try {
          const response = await axios.post(`${url}/signup-step-1`, {
            email,
            firstName,
            lastName,
            phone,
            referralCode,
          });
          console.log(response.data);
          if (response.data.email == false) {
            toast.error("Email already exist!", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (response.data.number == false) {
            toast.error("Number already exist!", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (response.data.Referr == false) {
            toast.error("Wrong Referral Code!", {
              position: "top-center",
              autoClose: 2000,
            });
          } else if (response.data.Referr) {
            toast.success("successful!", {
              position: "top-center",
              autoClose: 2000,
            });
            setGetGmailOtp(response.data.gmailOtp);
            setGetMobileOtp(response.data.mobileNumberOtp);
            setAnimationClass("slide-out-right");
            setTimeout(() => {
              setStep(2);
              setAnimationClass("slide-in-left");
            }, 300);
          } else {
            console.log(">>>>>>>");
            toast.error("Proceed failed!", {
              position: "top-center",
              autoClose: 2000,
            });
          }
          setLoading(false);
          alert(response.data.gmailOtp);
        } catch (error) {
          console.error("Error:", error);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      } else if (step === 3) {
        setLoading(true);
        if (pin == confirmPin) {
          try {
            const response = await axios.post(`${url}/signup-step-3`, {
              email,
              firstName,
              lastName,
              phone,
              referralCode,
              pin,
            });
            Swal.fire({
              title: "Success!",
              text: "Your account has been created successfully!",
              icon: "success",
            });

            navigate("/login");
          } catch (error) {
            Swal.fire({
              title: "Error",
              text: "An error occurred while creating your account.",
              icon: "error",
            });
          } finally {
            setLoading(false);
          }
        } else {
          toast.error("Pin and Confirm Pin is not same!", {
            position: "top-center",
            autoClose: 2000,
          });
          setLoading(false);
        }
      }
    }
    setLoading(false);
  };

  const handleOtpChange = (e, index, otpType) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      const newOtp = otpType === "gmail" ? [...gmailOtp] : [...mobileOtp];
      newOtp[index] = value;

      otpType === "gmail" ? setGmailOtp(newOtp) : setMobileOtp(newOtp);

      if (index < 5) {
        const nextInput = document.getElementById(
          `${otpType}-otp-input-${index + 1}`
        );
        if (nextInput) nextInput.focus();
      }
    } else {
      e.target.value = "";
    }
  };

  const handleKeyPress = (e, index, otpType) => {
    const currentOtp = otpType === "gmail" ? gmailOtp : mobileOtp;

    if (e.key === "Backspace") {
      const newOtp = [...currentOtp];
      newOtp[index] = "";

      otpType === "gmail" ? setGmailOtp(newOtp) : setMobileOtp(newOtp);

      if (index > 0 && currentOtp[index] === "") {
        const prevInput = document.getElementById(
          `${otpType}-otp-input-${index - 1}`
        );
        if (prevInput) prevInput.focus();
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      const prevInput = document.getElementById(
        `${otpType}-otp-input-${index - 1}`
      );
      if (prevInput) prevInput.focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      const nextInput = document.getElementById(
        `${otpType}-otp-input-${index + 1}`
      );
      if (nextInput) nextInput.focus();
    }
  };

  const handlecheck = () => {
    const gmailOtpString = gmailOtp.join("");
    const mobileOtpString = mobileOtp.join("");
    console.log(gmailOtpString, mobileOtpString);
    if (gmailOtpString == "" || mobileOtpString == "") {
      toast.error("Enter OTP!", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      if (gmailOtpString != getGmailOtp) {
        toast.error("Gmail OTP is wrong!", {
          position: "top-center",
          autoClose: 2000,
        });
      } else if (mobileOtpString != GetMobileOtp) {
        toast.error("Mobile No OTP is wrong!", {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        toast.success("Sign Up Successful !", {
          position: "top-center",
          autoClose: 2000,
        });
        setAnimationClass("slide-out-right");
        setTimeout(() => {
          setStep(3);
          setAnimationClass("slide-in-left");
        }, 300);
        // setStep(3);
      }
    }
  };

  return (
    <div className="signup-container">
      <div className={`signup-box`}>
        <Stepper step={step} />

        <h2>Welcome to X-Algos! 👋</h2>
        <p className="ranpq">Please sign up to create a new account</p>
        <ToastContainer />
        <div className={`signup-content ${animationClass}`}>
          {step === 1 && (
            <form>
              <label className="signup-label">First Name</label>
              <input
                className="signup-input"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <label className="signup-label">Last Name</label>
              <input
                className="signup-input"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <label className="signup-label">Email</label>
              <input
                className="signup-input"
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label className="signup-label">Phone</label>
              <input
                className="signup-input"
                type="text"
                placeholder="Enter Phone Number"
                value={phone}
                maxLength={10}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setPhone(value);
                }}
                required
              />
              <label className="signup-label">Referral Code(optional)</label>
              <input
                className="signup-input"
                type="text"
                placeholder="Enter Referral Code"
                value={referralCode}
                maxLength={11}
                onChange={(e) => setReferralCode(e.target.value)}
              />
              <button
                className={`signup-button ${loading ? "p-2" : ""}`}
                type="button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <span className="loading-spinner"></span> // Add your spinner here
                ) : (
                  <span className="asdfghjklqwertyuiop">Proceed</span>
                )}
              </button>
            </form>
          )}
          {step === 2 && (
            <div>
              <p>Verify your Gmail and Mobile OTP</p>
              <label className="signup-label">Gmail OTP</label>
              <div>
                {gmailOtp.map((otp, index) => (
                  <input
                    key={`gmail-${index}`}
                    id={`gmail-otp-input-${index}`}
                    type="text"
                    className="otp-input"
                    value={otp}
                    maxLength={1}
                    onChange={(e) => handleOtpChange(e, index, "gmail")}
                    onKeyDown={(e) => handleKeyPress(e, index, "gmail")}
                  />
                ))}
              </div>
              <label className="signup-label">Mobile OTP</label>
              <div>
                {mobileOtp.map((otp, index) => (
                  <input
                    key={`mobile-${index}`}
                    id={`mobile-otp-input-${index}`}
                    type="text"
                    className="otp-input"
                    value={otp}
                    maxLength={1}
                    onChange={(e) => handleOtpChange(e, index, "mobile")}
                    onKeyDown={(e) => handleKeyPress(e, index, "mobile")}
                  />
                ))}
              </div>
              <div className="asadfsjdnkjvd">
                <button
                  className={`signup-button ${loading ? "p-2" : "p-3"}`}
                  type="button"
                  onClick={handlecheck}
                >
                  {loading ? (
                    <span className="loading-spinner"></span> // Add your spinner here
                  ) : (
                    <span className="asdfghjklqwertyuiop">Next</span>
                  )}
                </button>
                <button
                  className={`signup-button ${loading ? "p-2" : "p-3"}`}
                  type="button"
                  onClick={() => {
                    setAnimationClass("slide-out-left");
                    setTimeout(() => {
                      setStep(1);
                      setAnimationClass("slide-in-right");
                    }, 300);
                  }}
                >
                  {loading ? (
                    <span className="loading-spinner"></span> // Add your spinner here
                  ) : (
                    <span className="asdfghjklqwertyuiop">Back</span>
                  )}
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <form>
              <label className="signup-label">Pin</label>
              <input
                className="signup-input"
                type="text"
                placeholder="Confirm Pin"
                value={pin}
                maxLength={4}
                onChange={(e) => {
                  // Allow only numeric input
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setPin(value);
                }}
                required
              />
              <label className="signup-label">Confirm Pin</label>
              <input
                className="signup-input"
                type="text"
                placeholder="Confirm Pin"
                value={confirmPin}
                maxLength={4}
                onChange={(e) => {
                  // Allow only numeric input
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  setConfirmPin(value);
                }}
                required
              />
              <button
                className={`signup-button ${loading ? "p-2" : "p-3"}`}
                type="button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <span className="loading-spinner"></span> // Add your spinner here
                ) : (
                  <span className="asdfghjklqwertyuiop">Submit</span>
                )}
              </button>
            </form>
          )}
        </div>
        <div className="signin-signup">
          Already have an account?
          <Link to="/login" className="ajsndjkasndkjasndjkasnkjd ml-1">
            Sign In here
          </Link>
        </div>
      </div>

      <div className="lottie-animation">
        <Lottie options={defaultOptions} height={450} width={450} />
      </div>
    </div>
  );
}

export default New_Signup;
