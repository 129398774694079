import React, { useState } from "react";
import "./WithdrawFundsUI.css";

const WithdrawFundsUI = () => {
  const [Amount, setAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleQuickAdd = (amount) => {
    setAmount((prev) => prev + parseInt(amount));
  };

  const transactions = [
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    // Add more transactions here
  ];

  const totalPages = Math.ceil(transactions.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentTransactions = transactions.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  return (
    <div className="withdraw-funds-container">
      <div className="left-section">
        <p className="account-balance">
          Account Funds: <b>₹</b>
        </p>
        <div>
          <label className="label-wallet">Amount</label>
          <input
            type="number"
            placeholder="Enter amount"
            value={Amount || ""}
            onChange={(e) =>
              setAmount(e.target.value ? parseInt(e.target.value, 10) : 0)
            }
            className="amount-input"
          />
        </div>

        <div className="upi-section">
          <label className="label-wallet">Enter UPI Id</label>
          <input
            type="text"
            placeholder="Enter your UPI (xxxx@Bankname)"
            className="upi-input"
          />
        </div>
        <div className="amount-summary">
          <p className="amount-to-withdraw">
            Amount to Add: <b>₹{Amount}</b>
          </p>
          <button className="withdraw-button">Withdraw</button>
        </div>
      </div>

      <div className="right-section">
        <table className="transactions-table">
          <thead>
            <tr>
              <th>Date/Time</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.map((row, index) => (
              <tr key={index} className="transaction-row">
                <td>{row.date}</td>
                <td className="red-amount">- {row.amount}</td>
                <td className="green-amount">{row.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-section">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`pagination-button ${
                currentPage === index + 1 ? "active" : ""
              }`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WithdrawFundsUI;
