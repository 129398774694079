import React, { useState } from "react";
import "./AddFundsUI.css";
import "./DepositBtn.css";

const AddFundsUI = () => {
  const [Amount, setAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const handleQuickAdd = (amount) => {
    setAmount((prev) => prev + parseInt(amount));
  };

  const transactions = [
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    { date: "10 Dec '24, 8:35 AM", amount: "₹1.00", status: "Success" },
    { date: "30 Sep '24, 10:07 AM", amount: "₹500.00", status: "Success" },
    // Add more transactions here
  ];

  const totalPages = Math.ceil(transactions.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentTransactions = transactions.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  return (
    <div className="add-funds-container">
      <div className="left-panel">
        <p className="account-funds text-start">
          Account Balance: <b>₹</b>
        </p>
        <div>
          {/* <label className="label">Amount</label> */}
          <input
            type="number"
            placeholder="Enter amount"
            value={Amount || ""}
            onChange={(e) =>
              setAmount(e.target.value ? parseInt(e.target.value, 10) : 0)
            }
            className="amount-input"
          />
        </div>
        <div className="quick-add-container">
          {["500", "1000", "2000", "5000"].map((amount) => (
            <button
              key={amount}
              className="quick-add-button"
              onClick={() => handleQuickAdd(amount)}
            >
              +₹{amount}
            </button>
          ))}
        </div>
        <div className="quick-add-container-mobile d-flex flex-columns w-100">
          <div className="w-100">
            {["500", "1000"].map((amount) => (
              <button
                key={amount}
                className="quick-add-button-mobile"
                onClick={() => handleQuickAdd(amount)}
              >
                +₹{amount}
              </button>
            ))}
          </div>
          <div className="w-100">
            {["2000", "5000"].map((amount) => (
              <button
                key={amount}
                className="quick-add-button-mobile"
                onClick={() => handleQuickAdd(amount)}
              >
                +₹{amount}
              </button>
            ))}
          </div>
        </div>

        <div className="upi-container">
          {/* <label className="label">Enter UPI Id</label> */}
          <input
            type="text"
            placeholder="Enter your UPI (xxxx@Bankname)"
            className="upi-input"
          />
        </div>
        <div className="amount-container">
          <p className="amount-to-add text-start">
            Amount to Add: <b>₹{Amount}</b>
          </p>
          <button className="deposit-button btn-success">Add Funds</button>
          {/* <button class="animated-button">
            <svg
              viewBox="0 0 24 24"
              class="arr-2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
            </svg>
            <span class="text">Add Funds</span>
            <span class="circle"></span>
            <svg
              viewBox="0 0 24 24"
              class="arr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
            </svg>
          </button> */}
        </div>
      </div>

      <div className="right-panel">
        <p className="ajsbdhasbdhasbdbashj">Transaction</p>
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Date/Time</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.map((row, index) => (
              <tr key={index} className="transaction-row">
                <td>{row.date}</td>
                <td className="green-text">+ {row.amount}</td>
                <td className="green-text">{row.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`pagination-button ${
                currentPage === index + 1 ? "active" : ""
              }`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddFundsUI;
