import React, { useEffect, useState } from "react";
import Modal from "react-modal"; // Ensure to install react-modal
import "./executedTrade.css";
import { ProductionUrl } from "../URL/url";
import { useSelector } from "react-redux";
import axios, { all } from "axios";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton
import Spinner from "./Spinner";
import Navbar from "./Navbar";

function ExecutedTrade({ darkMode, toggleDarkMode, setLoading }) {
  const email = useSelector((state) => state.email.email);
  const XId = useSelector((state) => state.email.XId);

  const url =
    process.env.NODE_ENV === "production"
      ? ProductionUrl
      : "http://localhost:5000";

  const userSchema = useSelector((state) => state.account.userSchemaRedux);

  const [allSheetData, setAllSheetData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedStrategyName, setSelectedStrategyName] = useState("");
  const [tableLoader, setTableLodaer] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [dayCount, setDayCount] = useState("");
  const [calculatedDate, setCalculatedDate] = useState(null);
  const [activeBtn, setActiveBtn] = useState("today");

  useEffect(() => {
    const fetchData = async () => {
      setTableLodaer(true);
      console.log(darkMode);
      const response = await axios.post(`${url}/fetchAllSheetData`, {
        email,
      });
      setAllSheetData(response.data.allSheetData);
      setTableLodaer(false);
    };
    fetchData();
  }, []);

  const handleCustomDate = () => {};

  const handleFilterToday = () => {
    // Get today's date in YYYY-MM-DD format
    setActiveBtn("today");
    const today = new Date();
    const formattedToday =
      today.getFullYear() +
      "-" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(today.getDate()).padStart(2, "0"); // Format: YYYY-MM-DD

    console.log("Today's date (formatted):", formattedToday);

    // Filter the data
    const filteredClients = filteredData.map((client) => {
      const filteredSheetData = client.sheetData.filter(
        (row) => row[3] === formattedToday
      );
      return {
        ...client,
        sheetData: filteredSheetData,
      };
    });

    console.log(filteredClients);
    setFilteredData(filteredClients);
  };

  const calculateDate = () => {
    const today = new Date();
    const resultDate = new Date(today);
    resultDate.setDate(today.getDate() - dayCount);
    const formattedDate = resultDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    setCalculatedDate(formattedDate);

    const filteredClients = filteredData2.map((client) => {
      const filteredSheetData = client.sheetData.filter((row) => {
        const rowDate = new Date(row[3]); // Assuming row[3] is the date in YYYY-MM-DD format
        return rowDate >= resultDate && rowDate <= today;
      });
      return {
        ...client,
        sheetData: filteredSheetData,
      };
    });

    console.log(filteredClients);
    setFilteredData(filteredClients);
  };

  useEffect(() => {
    const filtered = allSheetData.filter(
      (item) =>
        (selectedUserId ? item.UserId === selectedUserId : true) &&
        (selectedStrategyName
          ? item.strategyName === selectedStrategyName
          : true)
    );
    if (activeBtn == "today") {
      setActiveBtn("today");
      const today = new Date();
      const formattedToday =
        today.getFullYear() +
        "-" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(today.getDate()).padStart(2, "0"); // Format: YYYY-MM-DD

      console.log("Today's date (formatted):", formattedToday);

      // Filter the data
      const filteredClients = filtered.map((client) => {
        const filteredSheetData = client.sheetData.filter(
          (row) => row[3] === formattedToday
        );
        return {
          ...client,
          sheetData: filteredSheetData,
        };
      });
      setFilteredData(filteredClients);
    } else {
      const today = new Date();
      const resultDate = new Date(today);
      resultDate.setDate(today.getDate() - dayCount);
      const formattedDate = resultDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      setCalculatedDate(formattedDate);

      const filteredClients = filtered.map((client) => {
        const filteredSheetData = client.sheetData.filter((row) => {
          const rowDate = new Date(row[3]); // Assuming row[3] is the date in YYYY-MM-DD format
          return rowDate >= resultDate && rowDate <= today;
        });
        return {
          ...client,
          sheetData: filteredSheetData,
        };
      });

      console.log(filteredClients);
      setFilteredData(filteredClients);
    }
    setFilteredData2(filtered);
  }, [selectedUserId, selectedStrategyName, allSheetData]);

  const allTrades = filteredData
    .flatMap((item) =>
      item.sheetData.map((trade) => ({
        ...trade, // Spread `trade` to retain existing data
        UserId: item.UserId, // Attach `UserId` to each trade
        strategyName: item.strategyName, // Attach `UserId` to each trade
      }))
    )
    .map((trade, index) => ({
      id: index + 1,
      symbol: trade[1],
      type: trade[2],
      entryDate: trade[3],
      entryTime: trade[4],
      exitDate: trade[5],
      exitTime: trade[6],
      entryPrice: trade[7],
      exitPrice: trade[8],
      contract: trade[9],
      pnl: trade[10],
      broker: trade.UserId, // Now `UserId` is part of each trade
      strategy: trade.strategyName, // Now `UserId` is part of each trade
    }));

  console.log(allTrades);
  const handleOpenCustomModal = () => setIsCustomModalOpen(true);
  const handleCloseCustomModal = () => setIsCustomModalOpen(false);
  const handleDownloadStatement = () => {
    console.log("Download Statement clicked");

    // Get the current date and time
    const now = new Date();
    const downloadDate = now.toISOString().split("T")[0]; // Format: YYYY-MM-DD
    const downloadTime = now.toTimeString().split(" ")[0]; // Format: HH:MM:SS

    // User email (ensure this variable is available in your scope)
    const userEmail = email; // Replace 'email' with your actual variable holding the email

    // First section: Download details
    const downloadDetailsHeaders = [
      "Download Date",
      "Download Time",
      "User Email",
      "X-Algo Id",
    ];
    const downloadDetails = [downloadDate, downloadTime, userEmail, XId];

    // Second section: Trade data headers
    const tradeHeaders = [
      "Trade Broker",
      "Strategy",
      "Symbol",
      "Type",
      "Entry Date",
      "Entry Time",
      "Exit Date",
      "Exit Time",
      "Entry Price",
      "Exit Price",
      "Contract",
      "P&L",
    ];

    // Prepare the trade data rows
    const tradeRows = allTrades.map((trade) => [
      trade.broker,
      trade.strategy,
      trade.symbol,
      trade.type,
      trade.entryDate,
      trade.entryTime,
      trade.exitDate,
      trade.exitTime,
      trade.entryPrice,
      trade.exitPrice,
      trade.contract,
      trade.pnl,
    ]);

    // Combine all sections into the final CSV content
    const csvContent = [
      // First section: Download details
      downloadDetailsHeaders.join(","),
      downloadDetails.join(","),

      // Add an empty line to separate the sections
      "",

      // Second section: Trade data
      tradeHeaders.join(","),
      ...tradeRows.map((row) => row.join(",")),
    ].join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element for downloading
    const link = document.createElement("a");
    link.href = url;
    link.download = `Executed_Trades_${downloadDate}.csv`; // Dynamic filename
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary link element
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleCustomBtn = () => {
    setActiveBtn("custom");
    setIsCustomModalOpen(true);
  };

  return (
    <div>
      <Navbar
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        setLoading={setLoading}
      />
      <div className="ExecutedTrades">
        <div className="container" style={{ marginTop: "7em" }}>
          {tableLoader ? (
            // <div className="skeleton-card row stats-container">
            //   <Skeleton height={60} width="25%" className="mr-1" />
            //   <Skeleton height={60} width="25%" className="mr-1" />
            //   <Skeleton height={60} width="12%" className="mr-1" />
            //   <Skeleton height={60} width="12%" className="mr-1" />
            //   <Skeleton height={60} width="20%" className="mr-1" />
            // </div>
            <div className="hjg gfhglio d-flex justify-content-center mt-3">
              <Spinner />
            </div>
          ) : (
            <div className="row ExecutedTradeRow">
              {/* UserId Dropdown */}
              <div className="col-md-3 Broker-id-Executed">
                <select
                  id="userId"
                  className="form-control ExecutedTradeSelect"
                  value={selectedUserId}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                >
                  <option value="" className="ExecutedTradeOptions">
                    All Users
                  </option>
                  {[...new Set(allSheetData.map((item) => item.UserId))].map(
                    (userId) => (
                      <option
                        key={userId}
                        value={userId}
                        className="ExecutedTradeOptions"
                      >
                        {userSchema.AccountAliases[userId]}
                      </option>
                    )
                  )}
                </select>
              </div>

              {/* StrategyName Dropdown */}
              <div className="col-md-3 Broker-id-Executed">
                <select
                  id="strategyName"
                  className="form-control ExecutedTradeSelect"
                  value={selectedStrategyName}
                  onChange={(e) => setSelectedStrategyName(e.target.value)}
                >
                  <option value="" className="ExecutedTradeOptions">
                    All Strategies
                  </option>
                  {[
                    ...new Set(allSheetData.map((item) => item.strategyName)),
                  ].map((strategyName) => (
                    <option
                      key={strategyName}
                      value={strategyName}
                      className="ExecutedTradeOptions"
                    >
                      {strategyName}
                    </option>
                  ))}
                </select>
              </div>

              {/* Buttons */}
              <div className="col-6 d-flex flex-lg-nowrap Executed-buttons flex-wrap  align-items-center gap-3">
                <button
                  className="btn"
                  onClick={handleFilterToday}
                  style={{
                    backgroundColor:
                      activeBtn === "today" ? "#F0B90B" : "#5a6268",
                    color: activeBtn === "today" ? "black" : "var(--bg-color)",
                  }}
                >
                  Today
                </button>
                <button
                  className="btn"
                  onClick={handleCustomBtn}
                  style={{
                    backgroundColor:
                      activeBtn === "custom" ? "#F0B90B" : "#5a6268",
                    color: activeBtn === "custom" ? "black" : "white",
                  }}
                >
                  Custom
                </button>
                <button
                  className="btn btn-success Executed-Download-btn d-flex align-items-center gap-2"
                  onClick={handleDownloadStatement}
                >
                  <img
                    src="https://img.icons8.com/?size=100&id=20FjgTazh8FG&format=png&color=ffffff"
                    alt="Download Icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Download Statement
                </button>
              </div>
            </div>
          )}

          {tableLoader ? (
            // <div className="skeleton-card row stats-container">
            //   <Skeleton height={60} width="100%" className="mr-1" />
            //   <Skeleton height={60} width="100%" className="mr-1" />
            //   <Skeleton height={60} width="100%" className="mr-1" />
            //   <Skeleton height={60} width="100%" className="mr-1" />
            // </div>
            <></>
          ) : (
            <div
              className=" row stats-container"
              style={{ paddingTop: "0.25em" }}
            >
              <div className="row mt-4">
                <div className="col">
                  <div className="scrollable-table-container">
                    <table className="table scrollable-table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Symbol</th>
                          <th>Type</th>
                          <th>Entry date</th>
                          <th>Entry time</th>
                          <th>Exit date</th>
                          <th>Exit time</th>
                          <th>Entry price</th>
                          <th>Exit price</th>
                          <th>P&L</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allTrades.map((trade) => (
                          <tr key={trade.id}>
                            <td>{trade.id}</td>
                            <td>{trade.symbol}</td>
                            <td>{trade.type}</td>
                            <td>{trade.entryDate}</td>
                            <td>{trade.entryTime}</td>
                            <td>{trade.exitDate}</td>
                            <td>{trade.exitTime}</td>
                            <td>{trade.entryPrice}</td>
                            <td>{trade.exitPrice}</td>
                            <td>{trade.pnl}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for Custom Days */}
      <Modal
        isOpen={isCustomModalOpen}
        onRequestClose={handleCloseCustomModal}
        contentLabel="Custom Days"
        className="confirm-modal"
        style={{
          content: {
            background: darkMode ? "#101013" : "white",
            padding: "20px",
            color: darkMode ? "white" : "black",
            borderRadius: "10px",
          },
        }}
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <h3 style={{ color: darkMode ? "white" : "black" }}>Custom Days</h3>
          <label
            htmlFor="dayCount"
            style={{
              marginBottom: "1em",
              textAlign: "start",
              color: darkMode ? "white" : "black",
            }}
          >
            Enter Day Count:
          </label>
          <input
            id="dayCount"
            type="number"
            className="form-control ExecutedTradeSelect"
            value={dayCount}
            style={{ color: darkMode ? "white" : "black" }}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value > 1) {
                setDayCount(value);
              } else {
                setDayCount(null); // Reset if input is invalid
              }
            }}
          />

          <div className="modal-actions mt-3">
            <button
              className="btn btn-danger mx-2"
              onClick={() => {
                setDayCount(null);
                handleCloseCustomModal();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-2"
              onClick={() => {
                if (dayCount > 0) {
                  calculateDate();
                  handleCloseCustomModal();
                }
              }}
              disabled={dayCount === null}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ExecutedTrade;
